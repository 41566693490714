@import 'libs/assets/theme/mixins.scss';
.wrapper {
  margin: 0 auto;
  counter-reset: item;
  font-family: 'Lato', sans-serif;
}

.wrapper ol {
  counter-reset: item;
  padding-inline-start: 0;
}
